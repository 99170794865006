import { Inject, Injectable } from '@angular/core';
import { PlatformDetectorService } from '../platform-detector.service';
import { Constants } from '../../../../constants/Constants';
import { UrlService } from '../url.service';
import { DOCUMENT } from '@angular/common';
import { Locale } from '../../../../translations/models.translations';
import { News } from '../../models/news';
import { NewsService } from '../news.service';
import { Routing } from '../../../../constants/Routing';
import {TagService} from "../tag.service";

@Injectable({
  providedIn: 'root'
})
export class OntheioService {

  constructor(private pd: PlatformDetectorService,
              private urlService: UrlService,
              private newsService: NewsService,
              private tagService: TagService,
              @Inject(DOCUMENT) private document: Document,
              @Inject('LOCALE') private locale: Locale,
              ) { }

  init() {
    if (this.pd.isBrowser) {
      const url = this.urlService.getFullUrl();
      const newsRe = new RegExp(Constants.NEWS_TRANSLITERATION_MATCHER, 'gm')
      const currentPath = window.location.pathname;

      if (newsRe.exec(url)) {
        return;
      }

      const script = this.document.createElement('script');
      script.setAttribute('async', 'true');
      script.src = 'https://cdn.onthe.io/io.js/qwwNukNeR0B9';


      const ioConfig = this.document.createElement('script');
      ioConfig.text = `window._io_config = window._io_config || {}; window._io_config["0.2.0"] = window._io_config["0.2.0"] || [];`;

      this.document.head.appendChild(script);
      this.document.head.appendChild(ioConfig);

      // @ts-ignore
      window._io_config[ '0.2.0' ].push({
        page_url: window.location.href,
        page_url_canonical: window.location.origin,
        page_title: document.title,
        page_type: currentPath === '/' || currentPath === '/ru/' ? 'main' : 'default',
        page_language: this.locale
      });
    }

  }

  trackNewsObject(news: News) {
    this.tagService.getAllTagsNamesByNewsId(news.id).subscribe(tags => {
      if (this.pd.isBrowser) {
        this.newsService.getNewsInfo(news.id).subscribe(data => {

          // @ts-ignore
          window._io_config[ '0.2.0' ].push({
            page_url: `${data.publicUrl}${window.location.search}`,
            page_url_canonical: data.publicUrlUk,
            page_title: data.title,
            page_type: data.pageType,
            page_language: data.lang,
            language_type: data.lang,
            article_authors: [data.author],
            article_categories: [data.category],
            article_subcategories: data.subcategories,
            article_type: 'longread',
            article_word_count: data.wordsCount,
            article_publication_date: data.pubDate,
            tags: tags
          });
        });
      }
    });
  }
}
